<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title><img src="./../assets/images/SustainAbility-logo.svg" class="w-30 mx-auto" /></ion-title>
        <ion-buttons slot="end">
          <ion-button @click="openModal(id, 'tutorial')">
            <ion-img :src="require('@/assets/Icons/help.svg')" class="w-6 h-6"></ion-img>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Tab 3</ion-title>
        </ion-toolbar>
      </ion-header>
      <h1>My Swaps</h1>
      <div class="flex">
        <ion-card class="shadow-none">
          <h2>Original Product Title</h2>
          <img src="" />
        </ion-card>
        <div class="w-16"></div>
        <ion-card class="shadow-none">
          <h2>New Product Title</h2>
          <img src="" />
        </ion-card>
      </div>
      <div class="border-b-2">
        <p>Room: Kitchen</p>
        <p>Available swaps: 9</p>
      </div>
      <div>
        <p router-link="/tabs/swap-history">Back - no change</p>
        <p>Edit / view alternate swaps</p>
        <p>Don't swap - remove</p>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonHeader, IonTitle, IonToolbar, IonContent, modalController } from '@ionic/vue';
import TutorialModal from '@/components/TutorialModal.vue';

export default  {
  name: 'my-swaps',
  components: { IonHeader, IonTitle, IonToolbar, IonContent },
    setup() {

        const openModal = async (id, type) => {
            const modal = await modalController.create({
                component: TutorialModal, //Modal is name of the component to render inside ionic modal
                componentProps: {
                    id: id,
                    type: type
                },
                swipeToClose: true,
                backdropDismiss: true,
            });
            return modal.present();
        };

        return {
            openModal
        };
    },
}
</script>
